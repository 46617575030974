import React from 'react'
import {useMovieDetails,} from "../../hooks/apis/movies";
import MovieModal from "../common/movieModal";


export default function MovieSmallCard(movie) {
  // Movie Info Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [movieID, setMovieID] = React.useState(null);
  const { isFetching: isFetchingMovie, data: movieData } = useMovieDetails(movieID,'movie');
  const handleInfoClick = (id) => {
    setMovieID(id);
    handleOpen();
  };

  return (
    <>    
      <div
        className="leader-cell d-flex align-items-center mb-2"
        style={{cursor:'pointer'}}
        onClick={() => handleInfoClick(movie?.movie?.movie_id)}
      >
        <img
          className="m-1"
          src={movie?.movie?.image}
          alt=""
          style={{ height: "60px" }}
        />
        <p className="m-0 px-2">{movie?.movie?.title}</p>
      </div>

      <MovieModal
        handleClose={handleClose}
        isFetchingMovie={isFetchingMovie}
        movieData={movieData}
        open={open}
      />
    </>
  );
}
