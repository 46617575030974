import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
} from "react-query";
import { toast } from "react-toastify";
import api from "../axios";
import raiseScoreGainAndLoss from "../../../components/common/raiseScoreGainAndLoss";
import {handleUpdateOrAdd} from '../guest/index'
import {setLocalstorageWithExpiration} from "../../../utils/index";
import { getUser } from "../../apis/Auth";
import axios from "axios";

const fetchLeaderboard = async (key, params) => {
  const response = await api.get("/api/movies/leaderboard/" , {
    params:params
  });
  return response.data;
};

export function useLeaderboard(params, enabled = true) {
  return useQuery(
    ["leaderboard", params],
    () => fetchLeaderboard("leaderboard", params),
    {
      enabled: enabled, // Query will only run if `enabled` is true
    }
  );
};

const fetchGlobalLeaderboard = async (key, params) => {
  const response = await api.get("/api/movies/leaderboard/global/" , {
    params:params
  });
  return response.data;
};

export function useGlobalLeaderboard(params) {
  return useQuery(["global-leaderboard", params], () =>
      fetchGlobalLeaderboard("globalleaderboard", params)
  );
};


const fetchWatchlist = async (key, params) => {
  const response = await api.get("/api/movies/watchlist/?page_size=200", {
    params: params,
  });
  return response.data;
};

export function useWatchlist(params) {
  return useQuery(["watchlist", params], () =>
    fetchWatchlist("watchlist", params),{
            refetchOnWindowFocus: false,
            manual: true,
        }
  );
};


const fetchMoviesAutoComplete = async (key, params) => {
  const response = await api.get("/api/movies/search-autocomplete/", {
      params: params,
  });
  return response.data;
};

export function useMoviesAutoComplete(params) {
    return useQuery(["MoviesAutoComplete", params], () =>
        fetchMoviesAutoComplete("MoviesAutoComplete", params)
    );
};






const fetch2randomMovies = async (key, params) => {
  const response = await api.get("/api/movies/choose-movie/", {
    params: params
  });
  return response.data;
};

export function use2RandomMovies(params) {

  return useQuery(["RandomMovies", params], () => fetch2randomMovies("RandomMovies", params), {
    refetchOnWindowFocus: false,
    manual: true
  });
};

const fetchYesNoMovies = async (key, params) => {
  const response = await api.get("/api/movies/yes-or-no-movies/", {
    params: params,
  });
  return response.data;
};

export function useYesNoMovies(params) {
  return useQuery(
    ["YesNoMovies", params],
    () => fetchYesNoMovies("YesNoMovies", params),
    {
      refetchOnWindowFocus: false,
      manual: true,
    }
  );
};

  


export function useSelectWinnerMovie() {
  const queryClient = useQueryClient();
  const { mutate, data:selectWinnerResponseData , isLoading } = useMutation(
    async (data) => {
      const response = await api.post("/api/movies/choose-movie/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        raiseScoreGainAndLoss(data);
        queryClient.refetchQueries("RandomMovies");
        queryClient.refetchQueries("profile");
        setTimeout(() => mutate(null), 5000);
      },
    }
  );

  return { mutate, selectWinnerResponseData, isLoading };
}


export function useToggleWatchlistMovie() {
  const queryClient = useQueryClient();
  const { mutate, data , isLoading } = useMutation(
    async (data) => {
      const response = await api.post("/api/movies/watchlist/toggle/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.msg);
        queryClient.refetchQueries("watchlist");
      },
    }
  );

  return { mutate, data, isLoading };
}





export function useToggleIsSeenWatchlistMovie() {
  const queryClient = useQueryClient();
  const { mutate, data , isLoading } = useMutation(
    async (data) => {
      const response = await api.post("/api/movies/watchlist/toggle/seen/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.msg);
        queryClient.refetchQueries("watchlist");
      },
    }
  );

  return { mutate, data, isLoading };
}


export function useResetLeaderboard() {
  const queryClient = useQueryClient();
  const { mutate, data , isLoading } = useMutation(
    async (data) => {
      const response = await api.post("/api/movies/leaderboard/reset/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.msg);
        queryClient.refetchQueries("leaderboard");
      },
    }
  );

  return { mutate, data, isLoading };
}


export function useCsvLeaderboard() {
  const queryClient = useQueryClient();

  const { mutate, data, isLoading } = useMutation(
    async (requestData) => {
      const response = await api.post(
        "/api/movies/leaderboard/csv/",
        requestData,
        {
          responseType: "blob", // Important for downloading files
        }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        // Create a URL for the blob data
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "leaderboard.csv"); // Define the file name
        document.body.appendChild(link);
        link.click();
        link.remove();

        // Optionally, refetch queries or show a success message
        // toast.success('CSV downloaded successfully');
        // queryClient.refetchQueries('leaderboard');
      },
    }
  );

  return { mutate, data, isLoading };
}

export function useResetHiddenMovies() {
  const { mutate, data , isLoading } = useMutation(
    async (data) => {
      const response = await api.post("/api/movies/hide-movie/reset/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.msg);
      },
    }
  );

  return { mutate, data, isLoading };
}





export function useSelectSearchResult() {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await api.post("/api/movies/select-search-result/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        const user = getUser();
        if (!user){
          setLocalstorageWithExpiration("selected_search", JSON.stringify(data));
        }
        queryClient.refetchQueries("RandomMovies");
      },
    }
  );
}

export function useUpdateMovie() {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await api.patch("/api/movies/edit-movie/", data);
      return response.data;
    },
    {
      onSuccess: (data, payload) => {
        let is_fetch_new_movies = !payload.hasOwnProperty("watching_status") && !payload.hasOwnProperty("in_watchlist");
        queryClient.refetchQueries("movie", data?.movie_id, data?.type);
        if (is_fetch_new_movies) {
          queryClient.refetchQueries("RandomMovies");
        }
      },
    }
  );
}

export function useHideMovie() {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await api.patch("/api/movies/hide-movie/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries("RandomMovies");
      },
    }
  );
}

export function useSetUserFilter() {
  const queryClient = useQueryClient();
  return useMutation(
      async (data) => {
          const response = await api.patch("/api/movies/set-filter/", data);
          return response.data;
      },
      {
          onSuccess: (data) => {
              // toast.success("Movie Selected ...");
              queryClient.refetchQueries("userFilter");
              queryClient.refetchQueries("RandomMovies");
              queryClient.refetchQueries("YesNoMovies");
          },
      }
  );
}


const getUserFilter = async (key, params) => {
  const response = await api.get("/api/movies/set-filter/", {
    params: params,
  });
  return response.data;
};

export function useGetUserFilter(params) {
    return useQuery(
        ["userFilter", params],
        () => getUserFilter("userFilter", params),
        {
            refetchOnWindowFocus: false,
            manual: true,
        }
    );
}


const getGenresList = async (key, params) => {
  const response = await api.get("/api/movies/get-genres/", {
    params: params,
  });
  return response.data;
};

export function useGenresList(params) {
  return useQuery(["genresList", params], () =>
    getGenresList("genresList", params)
  );
};





const getCompaniesList = async (key, params) => {
  const response = await api.get("/api/movies/companies-list/", {
      params: params,
  });
  return response.data;
};

export function useCompaniesList(params) {
    return useQuery(["CompaniesList", params], () =>
        getCompaniesList("CompaniesList", params)
    );
};


const getProvidersList = async (key, params) => {
  const response = await api.get("/api/movies/providers-list/", {
    params: params,
  });
  return response.data;
};

export function useProvidersList(params) {
    return useQuery(["ProvidersList", params], () =>
      getProvidersList("ProvidersList", params)
    );
};






export function useClearSearch() {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await api.post("/api/movies/clear-search/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        // toast.success("Movie Selected ...");
        queryClient.refetchQueries("RandomMovies");
      },
    }
  );
}




export function useCreateLockedMovie() {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await api.post("/api/movies/create-locked-movie/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        // toast.success("Movie Selected ...");
        queryClient.refetchQueries("RandomMovies");
      },
    }
  );
}

export function useDeleteLockedMovie(id) {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await api.delete(`/api/movies/locked-movie-delete/${id}`, data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries("RandomMovies");
      },
    }
  );
}



export function useSearchMovies() {
  // const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await api.post("/api/movies/search/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        // toast.success("Movie Selected ...");
        // queryClient.refetchQueries("RandomMovies");
      },

    }
  );
}

const searchMovie = async (key, params) => {
  const response = await api.get("/api/movies/search/", {
    params: params,
  });
  return response.data;
};

export function useSearchMovie(params) {
  return useQuery(["search-movie", params], () =>
    searchMovie("search-movie", params)
  );
};



// const fetchSearch = ({ queryKey }) => {
//   const data = queryKey[1];
//   console.log(data,'gggggggggg')
//   if (data) {
//     return api.get(`/api/movies/search/`, data);
//   } else {
//     return null;
//   }
// };

// export const useSearchMovies = (search) => {
//   return useQuery(["SearchMovies", search], fetchSearch, {
//     manual: true,
//     refetchOnWindowFocus: false,
//     refetchOnReconnect: false,
//     retry: 1,
//     retryDelay: 3000,
//   });
// };







const fetchMovieDetails = ({ queryKey }) => {
  const [movieID, type] = queryKey.slice(1);
  if (movieID) {
    return api.get(
      `/api/movies/movie-detail/?movie_id=${movieID}&type=${type}`
    );
  } else {
    return null;
  }
};

export const useMovieDetails = (movieID, type) => {
  return useQuery(["movie", movieID, type], fetchMovieDetails, {
    manual: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
    retryDelay: 3000,
  });
};

const fetchMovieTrailer = ({ queryKey }) => {
  const movieID = queryKey[1];
  if (movieID) {
    return api.get(`/api/movies/movie-trailer/?movie_id=${movieID}`);
  } else {
    return null;
  }
};

export const useMovieTrailer = (movie_id) => {
  return useQuery(["movie-trailer", movie_id], fetchMovieTrailer, {
    manual: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
    retryDelay: 3000,
  });
};


const getPlaylists = async (key, params) => {
  const response = await api.get("/api/movies/playlists/", {
    params: params,
  });
  return response.data;
};

export function usePlaylists(params) {
  return useQuery(["playLists", params], () =>
    getPlaylists("playLists", params)
  );
};



export function useCreatePlaylist() {
  const queryClient = useQueryClient();
  const {
    mutate,
    data,
    isLoading,
  } = useMutation(
    async (data) => {
      const response = await api.post("/api/movies/playlists/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries("playLists");
      },
    }
  );

  return { mutate, data, isLoading };
}


const deletePlaylist = async (movieID) => {
  if (movieID) {
    try {
      const response = await api.delete(`/api/movies/playlists/${movieID}/`);
      return response.data;
    } catch (error) {
      throw new Error("Failed to delete playlist");
    }
  } else {
    throw new Error("Movie ID is required");
  }
};

export const useDeletePlaylist = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePlaylist, {
    retry: 1,
    retryDelay: 3000,
    onSuccess: () => {
      queryClient.refetchQueries("playLists");
    },
    onError: (error) => {
      console.error("Error deleting playlist:", error.message);
    },
  });
};





const getPlaylist = ({ queryKey }) => {
  const movieID = queryKey[1];
  if (movieID) {
    return api.get(`/api/movies/playlists/${movieID}/`);
  } else {
    return null;
  }
};

export const useGetPlaylist = (movie_id) => {
  return useQuery(["playlist", movie_id], getPlaylist, {
    manual: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
    retryDelay: 3000,
  });
};


const updatePlaylist = async ({ playlistID, data }) => {
  if (playlistID) {
    try {
      const response = await api.patch(
        `/api/movies/playlists/${playlistID}/`,
        data
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to update playlist");
    }
  } else {
    throw new Error("Movie ID is required");
  }
};

export const useUpdatePlaylist = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ playlistID, data }) => updatePlaylist({ playlistID, data }),
    {
      retry: 1,
      retryDelay: 3000,
      onSuccess: () => {
        queryClient.refetchQueries("playLists");
      },
      onError: (error) => {
        console.error("Error updating playlist:", error.message);
      },
    }
  );
};

export function useTogglePlaylistSubscribe() {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading } = useMutation(
    async (data) => {
      const response = await api.post("/api/movies/playlists/toggle/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.msg);
        queryClient.refetchQueries("playLists");
      },
    }
  );

  return { mutate, data, isLoading };
}

